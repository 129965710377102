import { OperationType } from "holocene-services/tradeLanes.service/types";
import { getRouteString } from "holocene-utils/helpers";

export const routes = {
  /** ======== Dashboard ======== */
  dashboard: `/dashboard/operational`,
  performanceDashboard: `/dashboard/performance`,
  salesOpDashboard: `/sales/dashboard/operational`,
  salesPerfDashboard: `/sales/dashboard/performance`,
  purchaseOpDashboard: `/purchase/dashboard/operational`,
  purchasePerfDashboard: `/purchase/dashboard/performance`,
  controlTowerDashboard: `/sales/control-tower`,

  /** ======== Account ======== */
  logout: `/account/logout`,

  /** ======== Settings ======== */
  settings: `/settings/personal`,
  organizationSettings: `/settings/company`,
  addSKU: `/settings/company/add-sku`,
  addMaterial: `/settings/company/add-material`,
  editActionServiceProvider: `/settings/company/edit-action-service-provider`,

  /** ======== Trade Lanes - Policy Updates & Special Rules ======== */
  policyUpdates: `/trade-lanes/trade-policies`,
  specialRules: `/trade-lanes/special-rules`,

  /** ======== Trade Lanes - Private Shipping Processes ======== */
  salesTradelanes: `/sales/trade-lanes`,
  salesTradelaneCreate: `/sales/trade-lanes/create`,
  salesTradelaneEdit: `/sales/trade-lanes/edit`,
  salesTradelaneView: `/sales/trade-lanes/view`,
  salesTradeLaneV3: "/sales/trade-lanes/v3",
  purchaseTradelanes: `/purchase/trade-lanes`,
  purchaseTradelaneCreate: `/purchase/trade-lanes/create`,
  purchaseTradelaneEdit: `/purchase/trade-lanes/edit`,
  purchaseTradelaneView: `/purchase/trade-lanes/view`,

  /** ======== Workspace (Shipments) ======== */
  salesQueue: `/sales/in-queue`,
  salesDispatched: `/sales/dispatched`,
  salesDelivered: "/sales/delivered",
  purchaseQueue: "/purchase/in-queue",
  purchaseDispatched: "/purchase/dispatched",
  purchaseDelivered: "/purchase/delivered",
  salesEditShipment: `/sales/shipment/edit/:id`,
  salesEditConsolidatedShipment: `/sales/shipment/consolidated/edit/:id`,
  purchaseEditShipment: `/purchase/shipment/edit/:id`,
  purchaseEditConsolidatedShipment: `/purchase/shipment/consolidated/edit/:id`,
  salesSettings: `/sales/settings`,
  purchaseSettings: "/purchase/settings",
  purchaseOrderList: "/purchase/purchase-orders",

  search: "/search",
  /** ======== Delivery ======== */
  deliveryQueueEdit: "/sales/delivery/:id",
  deliveryDispatchedEdit: "/sales/delivery/:id",
  deliveryQueue: "/sales/delivery/in-queue",
  deliveryDispatched: "/sales/delivery/dispatched",
};

const operationRoutes = {
  controlTower: `/control-tower`,
  performanceDashboard: `/performance-dashboard`,
  operationalDashboard: `/dashboard/operational`,

  tradelanes: "/trade-lanes",
  tradelaneCreate: "/trade-lanes/create",
  tradelaneEdit: "/trade-lanes/edit",
  tradelaneView: "/trade-lanes/view",
  tradeLaneV3: "/trade-lanes/v3",

  shipmentsInQueue: "/in-queue",
  shipmentInQueueEdit: "/in-queue/edit/:id",
  shipmentConsolidatedInQueueEdit: "/in-queue/consolidated/edit/:id",
  shipmentsDispatched: "/dispatched",
  shipmentDispatchedEdit: "/dispatched/edit/:id",
  shipmentConsolidatedDispatchedEdit: "/dispatched/consolidated/edit/:id",
  shipmentsDelivered: "/delivered",
  shipmentsOrderList: "/purchase-orders",
  ShipmentDeliveryOrdersList: "/inbound-deliveries",

  settings: "/settings",

  deliveryQueueEdit: "/delivery/:id",
  deliveryDispatchedEdit: "/delivery/:id",
  bookTransportation: "/delivery/:id/book/:lspId",
  deliveryQueue: "/delivery/in-queue",
  deliveryDispatched: "/delivery/dispatched",
};

export const getOperationRoute = (
  routeKey: keyof typeof operationRoutes,
  operation: OperationType,
  query?: any
) => `/${operation}${getRouteString(operationRoutes[routeKey], query)}`;
