import { IShipmentAlert, IShipmentDocument } from "holocene-services/shipment.service";
import { useEffect, useState, useMemo, useReducer } from "react";
import io, { Socket } from "socket.io-client";
//Define the format of the alert we will receive here

type UserInfo = {
  accessToken: string;
};

class AlertWebSocket {
  public socket?: Socket;
  public status: "connected" | "connecting" | "disconnected" = "disconnected";

  private initializeSocket = () => {
    let token;

    try {
      const userInfo = localStorage.getItem("userInfo");
      // Abort if not string
      if (typeof userInfo !== "string") throw new Error("User info not found");

      // Destructure token
      const { accessToken } = JSON.parse(userInfo) as UserInfo;

      // Abort if token is not string and min length
      if (!(typeof accessToken === "string" && accessToken.length >= 1)) {
        throw new Error("Invalid user access token");
      }
      token = accessToken;
    } catch {
      // Catch any errors and return an empty headers object
    }
    const url = `${process.env.Base_URL}/wssHolocene`;

    this.socket = io(url, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    this.socket.on("connect", () => {
      this.status = "connected";
    });
    this.socket.on("disconnect", () => {
      this.status = "disconnected";
    });

    this.socket.connect();
    this.status = "connecting";

    return { socket: this.socket, status: this.status };
  };

  getSocket = () => {
    if (this.socket) {
      return { socket: this.socket, status: this.status };
    }
    return this.initializeSocket();
  };
}

const alertWebSocket = new AlertWebSocket();

export enum WebsocketAlertModelName {
  SHIPMENT_DOCUMENT_ALERT = "ShipmentDocumentAlert",
  SHIPMENT_DOCUMENT = "ShipmentDocument",
  SHIPMENT = "Shipment",
  SALES_DELIVERY = "SalesDelivery",
  SALES_DELIVERY_ALERT = "SalesDeliveryAlert",
  SKUS = "skus",
  BUNDLE_DOCS = "BundleDocs",
  SALES_WAREHOUSE = "SalesWarehouse",
  OUTBOUND_LOGISTICS = "OutboundLogistics",
  SALES_ADMIN = "SalesAdmin",
  LOAD_PLAN = "LoadPlan",
  BOOK_TRANSPORTATION = "BookTransportation",
  DELIVERY_TASK = "DeliveryTask",
  INBOUND_DELIVERY = "InboundDelivery",
}

export enum WebsocketAlertAction {
  CREATE = "create",
  UPDATE = "update",
  DELETE_MANY = "deletemany",
  DELETE = "delete",
  UPSERT = "upsert",
  CSV_IMPORT = "CSVImport",
  ERROR = "error",
  ASSOCIATE_DELIVERY = "associate_delivery",
}

export interface WebsocketAlert {
  modelName: WebsocketAlertModelName;
  action: WebsocketAlertAction;
  data: IShipmentAlert | IShipmentAlert[] | { message: string };
  notifications: [];
  extractedValues?: { doc_type: string; entities?: any };
  status?: number;
  bundleDocs: IShipmentDocument[];
  bundleErrors: string[];
  id?: number;
}
export interface IWebsocketAlertDelivery {
  modelName: WebsocketAlertModelName;
  action: WebsocketAlertAction;
  message: string;
  requestId: string;
  success: boolean;
  salesDeliveryId?: number;
  data?: { salesDeliveryId?: number; isDraft?: boolean };
}
export const useAlertWebSocket = (onAlert: (alert: WebsocketAlert) => void) => {
  const [_, forceRender] = useReducer((s) => s + 1, 0);
  const { socket, status } = useMemo(() => alertWebSocket.getSocket(), []);

  const connected = status === "connected";

  useEffect(() => {
    const onConnectionChange = forceRender;
    socket.on("disconnect", onConnectionChange);
    socket.on("connect", onConnectionChange);

    return () => {
      socket.off("connect", onConnectionChange);
      socket.off("disconnect", onConnectionChange);
    };
  }, [connected]);

  useEffect(() => {
    if (connected) {
      socket.on("alerts", onAlert);

      return () => {
        socket.off("alerts", onAlert);
      };
    }
  }, [onAlert, connected]);

  return { connected, disconnect: socket.disconnect };
};
export const useAlertWebSocketDelivery = (onAlert: (alert: IWebsocketAlertDelivery) => void) => {
  const [_, forceRender] = useReducer((s) => s + 1, 0);
  const { socket, status } = useMemo(() => alertWebSocket.getSocket(), []);
  const connected = status === "connected";

  useEffect(() => {
    const onConnectionChange = forceRender;
    socket.on("disconnect", onConnectionChange);
    socket.on("connect", onConnectionChange);

    return () => {
      socket.off("connect", onConnectionChange);
      socket.off("disconnect", onConnectionChange);
    };
  }, []);

  useEffect(() => {
    if (connected) {
      socket.on("alerts", onAlert);

      return () => {
        socket.off("alerts", onAlert);
      };
    }
  }, [onAlert, connected]);

  return { connected, disconnect: socket.disconnect };
};
