import { TransportModeNameV2 } from "holocene-components/common/new/TransportIcon";
import { IDocumentType } from "holocene-components/trade-lanes/types";
import { TradeLaneStep } from "holocene-providers/tradeLanes/TradeLaneFormContext";
import { DocumentTypeEnum } from "holocene-services/delivery.service/types";
import { ICountry } from "holocene-services/user.service";
import { Dispatch, SetStateAction } from "react";

export interface ITLRequestDocument {
  documentId: number;
  certified?: boolean;
  legalized?: boolean;
}

export interface ICreateTradeLaneReq {
  name: string;
  destinationCountryId: number;
  originCountryId: number;
  productDifferentOrigin: boolean;
  keyContact: string;
  incoTermId: number;
  hsCodeIds: Array<{ hsCodeId: number }>;
  documents: Array<ITLRequestDocument>;
}

export interface IIncoterm {
  applicableTo: string;
  code: string;
  id: number;
}

export interface IHSCode {
  description: string;
  hsCode: string;
  id: number;
  shipmentDocumentsId?: number;
  tradeLaneId: number;
  updatedByShipment?: boolean;
}

export interface ITradeLaneHSCode {
  hsCode: IHSCode;
  hsCodeId: number;
  id: number;
}

export interface ITradeLane {
  id: number;
  name: string;
  userId: number;
  destinationCountryId: number;
  originCountryId: number;
  policyCount: number | null;
  productDifferentOrigin: boolean;
  shipmentCount: number;
  incoterm: IIncoterm;
  incoTermId: number;
  tradeLaneDestinationCountry: ICountry;
  tradeLaneOriginCountry: ICountry;
  tradeLaneHSCodes: ITradeLaneHSCode[];
  keyContact: string | null;
  customerId: number;
  createdAt: string;
  updatedAt: string | null;
  isPublic: boolean | null;
  specialRuleCount: number;
  enableISPM: boolean;
  transportModes: [
    {
      id: number;
      tradeLaneId: number;
      transportModeId: number;
      transportMode: {
        id: number;
        name: TransportModeNameV2;
      };
    }
  ];
  customer: {
    name: string;
    shortName: string | null;
  };
  tradeLaneProjects: [];
  stepsCount: number;
  operationType: "purchase" | "sales";
}
export interface IPrivateTradeLaneNote {
  id?: number;
  noteName: string;
  tradeLaneNoteDetails?: IPrivateTradeLaneNoteDetail[];
}

export interface IPrivateTradeLaneNoteDetail {
  id?: number;
  key: string;
  value: string;
}

export interface IPublicTradeLane {
  tradeLaneId: number;
  id: number;
  originCountry: ICountry;
  destinationCountry: ICountry;
  destinationCountryId: number;
  originCountryId: number;
  productDifferentOrigin: boolean;
  incoterm: IIncoterm;
  publicTradeLaneHSCodes: ITradeLaneHSCode[];
  publicTradeLaneDocuments: IPublicTradeLaneDocument[];
}

export interface ICountryDocTypeRef {
  id: number;
  originCountryId: number;
  destCountryId: number;
  documentTypeId: number;
  documentType: IDocumentType;
}

export interface ITradeLaneDocumentBase {
  id: number;
  documentId: number;
  certified: boolean | null;
  legalized: boolean | null;
  document: {
    id: number;
    countryDocTypeRefId: number;
    countryDocTypeRef: ICountryDocTypeRef;
    issuingBody: string | null;
    name: string | null;
    static: boolean | null;
    createdAt: string | null;
  };
}

export interface IPrivateTradeLaneDocument extends ITradeLaneDocumentBase {
  tradeLaneId?: number;
}

export interface IPublicTradeLaneDocument extends ITradeLaneDocumentBase {
  publicTradeLaneId?: number;
}

export interface IDestinationCountryFilter {
  tradeLaneDestinationCountry: {
    id: number;
    alpha2Code: string;
    officialName: string;
  };
}

export interface IOriginCountryFilter {
  tradeLaneOriginCountry: {
    id: number;
    alpha2Code: string;
    officialName: string;
  };
}

export interface IIncotermFilter {
  incoterm: { id: number; code: string };
}

export interface ITradeLaneResponse {
  count: number;
  tradeLanes: {
    [key: string]: ITradeLane[];
  };
  modeOfTransports: { name: TransportModeNameV2; id: number }[];
  destinationCountries: IDestinationCountryFilter[];
  originCountries: IOriginCountryFilter[];
  incoterms: IIncotermFilter[];
  projects: { name: string; id: number }[];
}

export interface IPublicTradeLaneResponse {
  count: number;
  publicTradeLanes: IPublicTradeLane[];
}

export enum RecommendationType {
  steps = "STEPS",
  documents = "DOCUMENTS",
  policy = "POLICY_UPDATES",
  comments = "COMMENTS",
}

export interface IHSCodesData {
  destinationCountryCode: string;
  numberOfTopHSCodesToReturn: string;
  name: string;
  topHSCodes: string[];
}

export interface IDutyDetails {
  [key: string]: any;
  duty?: string;
  type?: string;
  destinationCurrency?: string;
  dutyMinims?: string;
  VatRate?: string;
  vatMinims?: string;
}

export interface GetPrivateTradeLanesParams {
  skip: number;
  take: number;
  groupBy: boolean;
  includeParentCustomer: boolean;
  destinationCountryIds?: string;
  originCountryIds?: string;
  incotermIds?: string;
  transportIds?: number[];
  operationTypes?: "sales" | "purchase";
  projectIds?: number;
  customerIds?: number[];
}

export interface GetTradeLaneDefParams {
  incotermId: number;
  shippingOperationType: string;
  modeOfTransportIds: string; // comma separated ids
}

export type OperationType = "purchase" | "sales";
export interface GetTradeLaneDefResponse {
  def: {
    id: number;
    incotermId: number;
    incoterm: { id: number; code: string; name: string; applicableTo: string };
    operationType: OperationType;
    transportModes: { transportMode: { id: number; label: "air" | "sea" | "land" } }[];
    udpatedAt: string;
  };
  steps: {
    id: number;
    name: string;
    metadata: string; // JSON,
    stepSequence: number;
    substeps: {
      id: number;
      name: string;
      metadata: string; //JSON
      transportModes?: { id: number; name: "air" | "land" | "sea" }[];
    }[];
  }[];
}

export interface GetTradeLaneDefTransportModeParams {
  incotermId: number;
  shippingOperationType: string;
}

export interface GetTradeLaneDefTransportModeResponse {
  id: number;
  name: "sea" | "air" | "land";
  enabled?: boolean;
}

export interface PostTradeLanePayload {
  tradeLaneDefId: number;
  operationType: OperationType;
  transportModeIds: number[];
  name: string;
  destinationCountryId: number;
  originCountryId: number;
  incoTermId: number;
  hsCodeIds: {
    hsCodeId: number;
  }[];
  productDifferentOrigin: boolean;
  steps: TradeLaneStep[];
  projects: PostProjectPayload[];
}

interface BaseTradeLaneProject {
  name: string;
  consigneeName: string;
  consigneeAddress: string;
  consigneeAddress2: string;
  deliveryAddress: string;
  deliveryAddress2: string;
  forwarderCompany: string;
  forwarderContactPerson: string;
  contactEmail: string;
  contactPhoneNo: string;
  notes: {
    key: string;
    value: string;
  }[];
  tradeLaneId: number;
}

export interface PostProjectPayload extends BaseTradeLaneProject {
  projectFiles: { fileId: string; fileName: string }[];
}
export interface TradeLaneProject extends BaseTradeLaneProject {
  id: number;
  projectFiles: { id: number; file_url: string; fileName: string }[];
}

export interface UserForwarder {
  createdAt: string;
  customerId: number;
  forwarderName: string;
  forwarderEmail: { emailId: string }[];
  id: number;
  updatedAt: string;
  userId: number;
}

export interface UserProvider {
  createdAt: string;
  customerId: number;
  providerName: string;
  id: number;
  updatedAt: string;
  userId: number;
  providerEmail: { emailId: string }[];
}

export type TLRuleThenType =
  | "changeOutboundLogisticsTransportationMode"
  | "addDocumentToAttachSection"
  | "addDocumentToApplySection"
  | "addDocumentToGenerateSection"
  | "addCustomDocumentName"
  | "addLegalizationRequired"
  | "addCopiesRequired"
  | "changeLogisticsServiceProvider";
export interface TradeLaneRuleOptions {
  possibleLeftOperands: {
    [key: string]: string;
  };
  possibleThens: { [thenType: string]: string };
  restrictionsByLeftOperand: {
    [key: string]: {
      leftOperand: string;
      leftOperandReadable: string;
      relationOperators: { relationOperator: string; relationOperatorReadable: string }[];
      rightOperand: {
        rightOperandValue: {
          type: "list" | "country" | "fixed" | "undrestricted";
          possibleValues?: { id: string; name: string }[]; // Only for list | country
          value?: string; // Only for fixed
        };

        rightOperandUnit: {
          type: "list" | "unrestricted" | "none";
          possibleValues?: { id: string; name: string }[];
        };
      };
      thenPossibilities: string[];
    };
  };
  restrictionsByThenTypes: {
    [key: string]: {
      thenType: TLRuleThenType;
      thenTypeReadable: string;
      thenValue: {
        type: "list" | "multiple-list" | "unrestricted" | "none";
        thenType: string;
        possibleValues?: { id: string; name: string }[];
        numberOfCopies?: number;
        possibleCopiesOf?: { id: string; name: string }[];
      };
    };
  };
}

export interface TradeLaneRuleCondition {
  leftOperand: string;
  leftOperandReadable: string;
  relationOperator: string;
  relationOperatorReadable: string;
  rightOperand: { value: string; unit: string };
  rightOperandReadable?: string;
}

export interface TradeLaneRule {
  rule: {
    conditions: TradeLaneRuleCondition[];
    thenClause: {
      type: string;
      payload: {
        value: string;
        readableValue: string;
      };
    };
    id: number;
    deliveries: number;
    lastUsed: string;
    createdAt?: string;
  };
}

export interface CreateTradeLaneRuleFormState {
  conditions: {
    leftOperand?: string;
    relationOperator?: string;
    rightOperandValue?: string;
    rightOperandUnit?: string;
  }[];
  thenType?: TLRuleThenType;
  thenPayload: {
    value: string;
    customValue?: string;
    list?: { value: string; copies?: number; id?: number }[];
  };
}

export interface CreateTradeLaneRulePayload {
  conditions: {
    leftOperand?: string;
    relationOperator?: string;
    rightOperandValue?: string;
    rightOperandUnit?: string;
  }[];
  thenType?: TLRuleThenType;
  thenPayload: { value: string | { [key: string]: number } | string[]; customValue?: string };
}

export interface TLConfigChoice {
  item: {
    documentInformationType: DocumentTypeEnum;
    isChecked: boolean;
  };
  transportationMode: {
    id: number;
    type: TransportModeNameV2;
    createdAt: string;
    updatedAt: string;
  };
  documentTypeInformation: {
    id: number;
    type: DocumentTypeEnum;
    createdAt: string;
    updatedAt: string;
  };
  documentInformationMap: {
    key: string;
    name: string;
    const: DocumentTypeEnum;
  };
}

export interface TLMOTDocConfig {
  kind: "custom" | "predefined";
  label: string | null;
  documentInformationType: DocumentTypeEnum;
  documentInformationName: string;
  customDocumentNameId?: number;
  documentTypeInformation: { id: number };
  isChecked: boolean;
  applicable: boolean;
  attachable: boolean;
  generatable: boolean;
  erpEnabled: boolean;
}
export interface TradeLaneMOTConfig {
  generate: TLMOTDocConfig[];
  attach: TLMOTDocConfig[];
  apply: TLMOTDocConfig[];
  erpEnabled: TLMOTDocConfig[];
  isSetup: true;
  autoGenerate: boolean;
}
export interface GetTradeLaneConfigResponse {
  [mot: string]: TradeLaneMOTConfig;
}

export interface PostTradeLaneMOTConfig {
  transportationModeType: TransportModeNameV2;
  apply: string[];
  attach: string[];
  generate: string[];
  customGenerateDocumentIds: number[];
  customAttachDocumentNames: string[];
}

type CreateDeliveryMethod = "none" | "email" | "api";
type InventoryReleaseMethod = "none" | "batchNumber" | "serialNumber";
type ManufacturingMethod = "none" | "makeToOrder" | "makeToStock";

export interface PatchMasterTradeLaneConfig {
  createDeliveryMethod?: CreateDeliveryMethod; // ---- default is none;
  createDeliveryMethodEmail?: string; // --- required when createDeliveryMethod is email;
  inventoryReleaseMethod?: InventoryReleaseMethod; // ---- default is none;
  manufacturingMethod?: ManufacturingMethod; //v ---- default is none;
  autoShare?: boolean; // ---- default is false;
  autoDispatch?: boolean; // ---- default is false;
  availableTransportationModes?: Array<{
    transportationModeId: number;
    default?: boolean;
  }>;
}

export interface MasterTradeLaneConfig {
  id: number;
  customerId: number;
  inventoryReleaseMethod: InventoryReleaseMethod;
  manufacturingMethod: ManufacturingMethod;
  createDeliveryMethod: CreateDeliveryMethod;
  createDeliveryMethodEmail?: string;
  createDeliveryMethodClientId: string;
  autoDispatch: boolean;
  autoShare: boolean;
  CustomerTradelaneAvailableMode: {
    id: number;
    customerTradeLaneConfigId: number;
    transportationModeId: number;
    default: boolean;
    transportationMode: {
      id: number;
      type: TransportModeNameV2;
      createdAt: string;
      updatedAt: string;
    };
  }[];
}

export interface TradeLaneFormState {
  createDeliveryMethod?: CreateDeliveryMethod;
  createDeliveryMethodEmail?: string;
  createDeliveryMethodClientId?: string;
  manufacturingMethod?: ManufacturingMethod;
  inventoryReleaseMethod?: InventoryReleaseMethod;
  skuEnabled?: boolean;
  availableTransportationModes?: {
    transportationModeId: number;
    name: TransportModeNameV2;
    default: boolean;
  }[];
  transportConfig?: {
    [key: string]: TradeLaneMOTConfig;
  };
  autoDispatch?: boolean;
  autoShare?: boolean;
}

export interface TransportConfigSection {
  generate: { label: string; value: string }[];
  apply: { label: string; value: string }[];
  attach: { label: string; value: number }[];
}

export interface TradeLaneSectionProps {
  formState: TradeLaneFormState;
  setFormState: Dispatch<SetStateAction<TradeLaneFormState>>;
  editable: boolean;
}
