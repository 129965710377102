import { tz } from "moment-timezone";
import zones from "moment-timezone/data/meta/latest.json";

enum mimeType {
  ".pdf" = "application/pdf",
  ".doc" = "application/msword",
  ".docx" = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}
export declare type TMimeType = keyof typeof mimeType;

export const nFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

export const getDisplayNumber = (num: number) => {
  let currentDenom = 1;
  let displayNumber = "";
  while (num >= currentDenom) {
    currentDenom = currentDenom;
    const numPart = (
      ((num % (currentDenom * 1000)) - (num % currentDenom)) / currentDenom || "000"
    ).toString();
    displayNumber = displayNumber ? numPart + "," + displayNumber : numPart;
    currentDenom = currentDenom * 1000;
  }
  return displayNumber || 0;
};

export const convertLabel = (type: string) => {
  const words = type.split("_");

  const label = words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");

  return label;
};

export const getUserId = () => {
  let userid: string | null = localStorage.getItem("userid");
  const { id } = userid ? (JSON.parse(userid) as any) : { id: "" };
  return id;
};

export const checkIfTokenExist = (): boolean => {
  try {
    const userInfo = localStorage.getItem("userInfo");
    if (typeof userInfo !== "string") {
      return false;
    }
    const { accessToken } = JSON.parse(userInfo).userInfo;
    if (typeof accessToken !== "string") {
      return false;
    }
    return true;
  } catch {
    return false;
  }
};

export const toSentenceCase = (text: string = "") => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Downloads a file from the browser
 * @param data - binary data sent from server
 * @param fileName - e.g. download.csv - make sure to pass the extension along with name
 */
export const downloadFile = (data: any, fileName: string, blobOptions?: BlobPropertyBag) => {
  const url = URL.createObjectURL(new Blob([data], blobOptions));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertFileToPdfBlob = (file: any) => {
  const arr = new Uint8Array(file);

  return new Blob([arr], { type: "application/pdf" });
};
export const convertFileToBlob = (file: any, ext: TMimeType = ".pdf") => {
  const arr = new Uint8Array(file);
  const type = mimeType[ext];
  return new Blob([arr], { type: type });
};

/**
 * Interpolates values into a string with placeholders - mainly intended for route and end point constants
 * @param string - string with placeholders
 * @param interpolationObj - object with the placeholder strings as key and the values to be replaced as value
 * Eg. getRouteString("test/:id", {id: "5678"}) => "test/5678"
 */
export const getRouteString = (
  string: string = "",
  interpolationObj: { [key: string]: string | number }
): string => {
  try {
    Object.entries(interpolationObj).forEach(([key, value]) => {
      string = string.replace(`:${key}`, value?.toString());
    });
  } catch (e) {}
  return string;
};

export function createDeepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export const compareArrays = (arr1: any[] = [], arr2: any[] = []) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

//Converts a JSON to a base 64 encoded string
export const encodeJSONToBase64 = (obj: any) => {
  try {
    return Buffer.from(JSON.stringify(obj)).toString("base64");
  } catch (err) {
    console.log("Error while encoding JSON: ", obj);
    return "";
  }
};

//Parses a base 64 encoded string as a JSON
export const decodeBase64ToJSON = (str: string) => {
  try {
    return JSON.parse(Buffer.from(str, "base64").toString());
  } catch (err) {
    console.log("Error while decoding string: ", str);
    return null;
  }
};

//Carries out a deep equality check on 2 variables recursively and returns true if they are deep equal
export const isDeepEqual = (item1: any, item2: any): boolean => {
  if (item1 === item2) return true;

  if (item1 && item2 && typeof item1 === "object" && typeof item2 === "object") {
    return JSON.stringify(item1) === JSON.stringify(item2);
  } else {
    return false;
  }
};

export const moveArrayElement = (array: any[], fromInd: number, toInd: number) => {
  let updatedArray = [...array];
  if (fromInd > toInd) {
    return [
      ...updatedArray.slice(0, toInd),
      array[fromInd],
      ...updatedArray.slice(toInd, fromInd),
      ...updatedArray.slice(fromInd + 1),
    ];
  } else if (fromInd < toInd) {
    return [
      ...updatedArray.slice(0, fromInd),
      ...updatedArray.slice(fromInd + 1, toInd),
      array[fromInd],
      ...updatedArray.slice(toInd),
    ];
  } else {
    return array;
  }
};

export const localeNumber = (num: number | string, options?: Intl.NumberFormatOptions) => {
  if (num === null || num === undefined) {
    return num;
  }
  const browserLocale = window.navigator.language;
  const timezone = tz.guess();
  const zone = (zones.zones as any)[timezone];
  const countryCode = zone?.countries[0];
  // Choose timezone locale - browser locale kept as a fallback
  const locale = countryCode || browserLocale;
  return typeof num === "string"
    ? Number(num).toLocaleString(locale, options)
    : num.toLocaleString(locale, options);
};

export const formatBigNumber = (num: number | string) => {
  if (num === null || num === undefined) {
    return num;
  }
  const actualNum = typeof num === "string" ? Number(num) : num;
  switch (true) {
    case actualNum >= 1e12:
      return `${(actualNum / 1e12).toFixed(1).replace(/\.0$/, "")} T`;
    case actualNum >= 1e9:
      return `${(actualNum / 1e9).toFixed(2).replace(/\.0$/, "")} B`;
    case actualNum >= 1e6:
      return `${(actualNum / 1e6).toFixed(1).replace(/\.0$/, "")} M`;
    case actualNum >= 1e3:
      return `${(actualNum / 1e3).toFixed(1).replace(/\.0$/, "")} K`;
    default:
      return localeNumber(actualNum);
  }
};

export const getIntersection = (arrs: (string | number)[][]) => {
  const intersection = [];

  let longestArr: (string | number)[] = [];
  for (const arr of arrs) {
    if (arr.length > longestArr.length) longestArr = arr;
  }

  for (let i = 0; i < longestArr.length; i++) {
    let item = longestArr[i];
    let isIntersecting = true;
    for (const arr of arrs) {
      if (arr !== longestArr) {
        if (!arr.includes(item)) {
          isIntersecting = false;
          break;
        }
      }
    }
    if (isIntersecting) intersection.push(item);
  }
  return intersection;
};
