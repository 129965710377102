import Image from "next/image";
import Link from "next/link";
import { Question, UserCircle } from "phosphor-react";
import { useUserDetails } from "holocene-providers/common";
import SearchInput from "../SearchInput";
import { getOperationRoute, routes } from "holocene-constants/routes";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useActiveOperation } from "holocene-hooks/utils.hooks";
import { OperationType } from "holocene-utils/types";
import CustomTooltip, { rebuildTooltips } from "../CustomTooltip";
import { useAuthActions, useAuthUser } from "@frontegg/nextjs";
import { useGetCurrentUserInfo, useGetUserTenants } from "holocene-hooks/users.hooks";
import { ModuleEnum } from "holocene-components/settings/UserDetailsModal";
import Select from "../new/Select";
import { UserTenant } from "holocene-services/user.service/types";
import { useQueryClient } from "react-query";
import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import { clearLocalStorage } from "holocene-utils/common.utils";

const NavItem = ({
  text,
  path,
  active,
  disabled,
}: {
  text: string;
  path: string;
  active?: boolean;
  disabled?: boolean;
}) => {
  const { asPath } = useRouter();

  const isActive = asPath.split("?")[0] === path || active;
  const Wrapper = isActive ? "div" : Link;
  const props = isActive ? {} : { passHref: true };
  return (
    <Wrapper href={path} {...props}>
      <div
        className={`cursor-pointer flex items-center !py-[6px] px-2.5 rounded ml-4 ${
          isActive
            ? "bg-holocene-blue pointer-events-none text-white"
            : "text-holocene-blue hover:bg-ice-blue-2"
        }`}
      >
        <div className={`text-xs  capitalize`}>{text}</div>
      </div>
    </Wrapper>
  );
};

const TenantSelect = ({ tenants }: { tenants: UserTenant[] }) => {
  const queryClient = useQueryClient();
  const selectedOption = tenants.find(({ active }) => active);
  const { switchTenant } = useAuthActions();
  const tenantOptions = tenants.map(({ name, shortName, fronteggTenantId }) => ({
    label: shortName || name,
    value: fronteggTenantId,
  }));

  return (
    <Select
      options={tenantOptions}
      onChange={(newTenant) => {
        switchTenant({
          tenantId: newTenant.value,
          callback: async (status, err) => {
            const allQueries = Object.values(QUERY_CONSTANTS);
            await queryClient.invalidateQueries(allQueries);
            clearLocalStorage();
            window.location.href = "/";
          },
        });
      }}
      className="min-w-[140px]"
      value={selectedOption?.fronteggTenantId}
      disabled={tenantOptions.length === 1}
      size="sm"
    />
  );
};

const Navbar = ({ hideSearch }: { hideSearch?: boolean }) => {
  const router = useRouter();
  const { selectedCustomerTypeId, isLoading } = useUserDetails();
  const user = useAuthUser();
  const { data: userInfo } = useGetCurrentUserInfo();
  const { data: userTenants } = useGetUserTenants();
  const activeOperation = useActiveOperation();

  useEffect(() => {
    rebuildTooltips();
  }, [user]);

  const tabOptions = useMemo(() => {
    let options: { label: string; href: string; active: boolean; disabled?: boolean }[] = [];
    if (router.pathname.startsWith("/search")) {
      return options;
    }

    const isSupportUser = userInfo?.roles.includes("Admin");

    const hasSalesRole = userInfo?.detailInfo.Roles.some(
      ({ Role }) => Role.moduleName === ModuleEnum.sales
    );

    const hasPurchaseRole = userInfo?.detailInfo.Roles.some(
      ({ Role }) => Role.moduleName === ModuleEnum.purchase
    );

    options = [
      // When selectedCustomerTypeId = 1, its an importer which is only purchase.
      ...(selectedCustomerTypeId !== 1 && (isSupportUser || hasSalesRole)
        ? [
            {
              label: "Sales",
              href: getOperationRoute("controlTower", OperationType.SALES),
              active: activeOperation === OperationType.SALES,
              disabled: activeOperation === OperationType.SALES,
            },
          ]
        : []),
      // When selectedCustomerTypeId = 2, its an exported which is only sales.
      ...(selectedCustomerTypeId !== 2 && (isSupportUser || hasPurchaseRole)
        ? [
            {
              label: "Purchase",
              href: getOperationRoute("controlTower", OperationType.PURCHASE),
              active: activeOperation === OperationType.PURCHASE,
              disabled: activeOperation === OperationType.PURCHASE,
            },
          ]
        : []),
    ];

    return options;
  }, [
    router.pathname,
    userInfo?.roles,
    userInfo?.detailInfo.Roles,
    selectedCustomerTypeId,
    activeOperation,
  ]);

  return (
    <div className="h-[56px] w-full flex items-center bg-white z-1 border-b border-strokes px-4">
      <div className="w-[46px] h-[26px]">
        <Link href="/" legacyBehavior>
          <a>
            <Image src="/logo.svg" width={25} height={26} alt="logo" />
          </a>
        </Link>
      </div>

      <div className="flex items-center w-full justify-between">
        <div className="flex w-full items-center">
          {isLoading ? (
            <></>
          ) : (
            <div className="flex items-center">
              {userTenants?.length && <TenantSelect tenants={userTenants} />}
              {tabOptions.map((opt) => (
                <NavItem
                  key={opt.label}
                  text={opt.label}
                  path={opt.href}
                  active={opt.active}
                  disabled={opt.disabled}
                />
              ))}
            </div>
          )}
        </div>
        <div className="flex items-center">
          {!hideSearch && <SearchInput />}
          <button id="intercom-launcher">
            <Question size={20} className="text-holocene-blue ml-4" />
          </button>
          <div data-tip={user?.name} className="hover:bg-ice-blue-2 ml-2 mr-6 p-2 cursor-pointer">
            <Link href={routes.settings} passHref legacyBehavior>
              <a>
                <UserCircle size={20} className="text-holocene-blue" />
              </a>
            </Link>
          </div>
        </div>
      </div>
      <CustomTooltip />
    </div>
  );
};
export default Navbar;
